export default class Option {
  entry
  active = false

  constructor(entry, active = false) {
    if (!entry) {
      return
    }
    this.entry = entry
    this.active = active
  }
}
